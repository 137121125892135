import type { TApiDataResponse } from '~/project-layers/dota/features/live-drop/api/types/api.types';
import { $api } from '~/api/global/ApiProvider';
import type { ILiveDropGetParams } from '~/project-layers/dota/features/live-drop/api/types/client.types';
import { transform } from '~/project-layers/dota/features/live-drop/api/types/adapter';
import type { ITopLiveDropItem } from '~/utils/liveDrop/parseWSData.types';

export class LiveDropRepository {
  private static dropApi = '/v1/live_drop';

  static async fetch(params: ILiveDropGetParams): Promise<ITopLiveDropItem[]> {
    const { data } = await $api.post<TApiDataResponse>(this.dropApi, {
      body: { ...GlobalUtils.Objects.snakeCaseParams(params) },
    });
    return transform(data, params.isUltraRare);
  }
}
