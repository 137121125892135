<template>
  <BaseDrop
    :background="computedBackground"
    :user="item.user"
    :is-ultra-rare="item.isUltraRare"
    :hover-image="item.images.hover"
    :has-multi-hover="hasMultiHover"
    :item-link="itemLink"
    :shining="shining"
    :shining-color="shiningColor"
  >
    <template #image>
      <img :src="item.images.default" alt="Drop image" />
    </template>
    <template #top-left-icon>
      <div v-if="topLeftIconComponent" :class="iconClass">
        <component :is="topLeftIconComponent" />
      </div>
      <div v-if="fromUpgrade" class="upgrade-icon">
        <SvgoUpgradeNew v-if="fromUpgrade" class="top-left-icon" />
      </div>
    </template>
    <template #upgrade-percent>
      <span v-if="item.additional?.percent" class="top-left-percent">{{ item.additional.percent }}</span>
    </template>
    <template #top-right-icon>
      <SvgoCL v-if="fromMulticast" class="top-right-icon" />
    </template>
    <template #bottom-left-icon>
      <SvgoST v-if="isStatTrack" class="bottom-left-icon" />
    </template>
  </BaseDrop>
</template>

<script setup lang="ts">
import type { ITopLiveDropItemProps } from '~/utils/liveDrop/parseWSData.types';
import BaseDrop from '~/components/BaseDrop/BaseDrop.vue';

const props = defineProps<ITopLiveDropItemProps>();

const computedBackground = computed(() => {
  const rarityColor = GlobalUtils.Colors.getColorsRarity(props.item.quality || '');

  return props.item.isUltraRare
    ? `linear-gradient(40deg, ${rarityColor} 0%, #0E1030 80%)`
    : `linear-gradient(40deg, ${rarityColor} 0%, #0E1030 80%)`;
});

// Геттер возвращающий тип для предмета
const sourceType = computed(() => {
  const { type, case: caseItem } = props.item || {};
  const caseName = caseItem?.name || '';

  if (type?.toLowerCase() === 'battle') return 'battle';
  if (type?.toLowerCase() === 'contract') return 'contract';
  if (type?.toLowerCase() === 'upgrade') return 'upgrade';
  if (caseName.includes('mltcst') || caseName.includes('multifix')) return 'multicast';
  if (caseName.includes('dailycase')) return 'freeCase';
  if (caseName.includes('coin')) return 'coinCase';
  if (caseName.includes('offer') || caseName?.includes('reward') || caseName?.includes('progress')) return 'offer';
  return 'default';
});

// isPayback отвечает за окупаемость пердмета, если цена предмета выше цены кейса возвращается true
const isPayback = computed(() => {
  return (props.item?.price ?? 0) > (props.item?.case?.price ?? 0);
});

// Константы отвечающие за то, откуда пришел предмет
const fromBattle = computed(() => sourceType.value === 'battle');
const fromContract = computed(() => sourceType.value === 'contract');
const fromUpgrade = computed(() => sourceType.value === 'upgrade');
const fromMulticast = computed(() => sourceType.value === 'multicast');
const fromFreeCase = computed(() => sourceType.value === 'freeCase');
const fromCoinCase = computed(() => sourceType.value === 'coinCase');
const fromOffer = computed(() => sourceType.value === 'offer');

// Будет ли показываться иконка в левом верхнем углу
const hasTopLeftIcon = computed(() => fromOffer.value || fromCoinCase.value || fromFreeCase.value || isPayback.value);
// Является ли предмет StatTrak
const isStatTrack = computed(() => props.item.name?.includes('StatTrak'));

// Ссылка на источник предмета
const itemLink = computed(() => {
  if (fromBattle.value) return useLinks().BATTLES;
  if (fromContract.value) return useLinks().CONTRACT;
  if (fromUpgrade.value) return `${useLinks().UPGRADES}${props.item.inventoryId}`;
  return useLinks().OPEN_CASE + props.item.case.name;
});

// Какая иконка будет показываться в левом верхнем углу
const topLeftIconComponent = computed(() => {
  if (hasTopLeftIcon.value && !(fromContract.value || fromBattle.value || fromUpgrade.value)) return 'SvgoFire';
  if (fromBattle.value) return 'SvgoBattlesNew';
  if (fromContract.value) return 'SvgoContractNew';
  return '';
});

const iconClass = computed(() => {
  const iconMap: Record<'SvgoFire' | 'SvgoBattlesNew' | 'SvgoContractNew', string> = {
    SvgoFire: 'fire-icon',
    SvgoBattlesNew: 'battles-icon',
    SvgoContractNew: 'contract-icon',
  };
  return iconMap[topLeftIconComponent.value as keyof typeof iconMap] || '';
});
</script>

<style scoped lang="scss" src="./Drop.scss" />
