<template>
  <SkeletonComponentLoader>
    <section v-if="!isLoaded" class="feed-controller-skeleton">
      <div class="feed-controller-skeleton__selector">
        <SkeletonBaseForeground :width="170" :height="68" :border-radius="8" />
      </div>
      <SkeletonBaseCupcake v-for="i in MAX_ELEMENTS" :key="i" />
    </section>
    <FeedController v-else />
  </SkeletonComponentLoader>
</template>

<script setup lang="ts">
import { MAX_ELEMENTS, useFeedStore } from '~/project-layers/dota/features/live-drop/store/feedStore';

const feedStore = useFeedStore();
const { isLoaded } = storeToRefs(feedStore);

await useAsyncData(async () => feedStore.getInitData(), { lazy: true, server: false });

onMounted(() => {
  feedStore.subscribe();
});
</script>

<style scoped lang="scss" src="./FeedController.skeleton.scss"></style>
